import { ReactNode, useMemo } from "react";
import tw from "tailwind-styled-components";
import Placeholder from "@c/placeholder";
import download from "@u/download";
import {
  useLoan,
  useGetPPI,
  useLoanDocument,
  useDocumentByLoan,
} from "@/hooks/use-loan";
import storage from "@/lib/storage";
import { DocumentsByLoans } from "@/types";

const Container = tw.div`flex flex-col gap-y-6 bg-white p-6 rounded-xl`;

const Heading = tw.p`text-[18.5px] font-bold text-pages-loan-detail-heading`;

const Content = tw.div`flex flex-col flex-wrap pt-0 gap-y-12 overflow-scroll h-auto w-full`;

const ItemGroup = tw.div`flex flex-col gap-y-3 w-full`;

const Item = tw.div`flex flex-row gap-x-6 items-center justify-between`;

const Label = tw.p`  uppercase text-sm font-bold text-pages-loan-detail-label`;

const Title = tw.p`w-2/6 text-pages-loan-detail-title capitalize`;

const Value = tw.div`flex gap-x-4 w-4/6 font-bold text-pages-loan-detail-value break-words items-center capitalize`;

const Link = tw.a`font-normal text-sm text-pages-loan-detail-title break-words capitalize underline hover:no-underline cursor-pointer`;

const Download = tw.button`font-normal text-sm text-pages-loan-detail-title break-words capitalize underline hover:no-underline cursor-pointer`;

const Status = tw.p`font-normal text-sm text-pages-loan-detail-title break-words capitalize`;

const Documents = ({ id }: IDocument) => {
  const isAdmin = storage.utilities.isAdmin();
  const { isLoading, isSuccess, isError, data } = useLoan(id, isAdmin);

  const { isSuccess: isLDSuccess, data: ldData } = useLoanDocument(id, true);
  const { isSuccess: isDLSuccess, data: dlData } = useDocumentByLoan(id, true);

  const { car } = isSuccess && JSON.parse(data?.data);

  const { isFetched: isPPIFetched, data: ppiData } = useGetPPI(
    car?.id,
    !!car?.id
  );

  const response = isSuccess && JSON.parse(data?.data);
  const dlResponse = isDLSuccess && JSON.parse(dlData?.data);
  const ppiResponse = isPPIFetched && JSON.parse(ppiData?.data);

  const documents =
    isSuccess &&
    response.customer?.profile?.filter(
      (profile: any) =>
        profile.type === "file" ||
        profile.value?.startsWith("https://storage.googleapis.com")
    );

  const documentData = useMemo(() => {
    if (isLDSuccess && ldData) {
      return JSON.parse(ldData?.data)?.documents?.filter(
        (data: Documents) => data?.status !== "REQUESTED"
      );
    } else {
      return [];
    }
  }, [isLDSuccess, ldData]);

  const requestedDocuments = useMemo(() => {
    if (isLDSuccess && ldData) {
      return JSON.parse(ldData?.data)?.documents?.filter(
        (data: Documents) => data?.status === "REQUESTED"
      );
    } else {
      return [];
    }
  }, [isLDSuccess, ldData]);

  const getItemState = (value: string | ReactNode | null, theme?: string) => {
    if (isLoading) {
      return <Placeholder color={theme} align="left" />;
    } else if (!isLoading && isSuccess) {
      return value || "---";
    } else if (isError) {
      return <Value>---</Value>;
    }
  };

  function camelToWords(key: string) {
    var result = key.replace(/([A-Z])/g, " $1");
    return result.split(" ").join(" ").toLowerCase();
  }

  return (
    <Container>
      <Heading>Documents</Heading>

      <Content>
        <ItemGroup>
          <Label>KYC Document</Label>

          {isSuccess &&
            documents?.map((document: any, index: string) => (
              <Item key={index}>
                <Title>{camelToWords(document.name)}:</Title>
                <Value>
                  {getItemState(
                    <Link href={document.value} target="_blank">
                      View
                    </Link>
                  )}

                  {getItemState(
                    <Download
                      onClick={() => download(document.value, document.id)}
                    >
                      Download
                    </Download>
                  )}
                </Value>
              </Item>
            ))}
          {documentData?.map((document: Documents, index: string) => (
            <Item key={index}>
              <Title>{camelToWords(document.name)}:</Title>
              <Value>
                {getItemState(
                  <Link href={document.fileUrl} target="_blank">
                    View
                  </Link>
                )}

                {getItemState(
                  <Download
                    onClick={() => download(document.fileUrl, document.name)}
                  >
                    Download
                  </Download>
                )}
              </Value>
            </Item>
          ))}
        </ItemGroup>

        {isPPIFetched && ppiResponse.result[0]?.pdfReport && (
          <ItemGroup>
            <Label>Loan Application</Label>
            <Item>
              <Title>Pre-Purchase Inspection Report:</Title>

              <Value>
                {getItemState(
                  <Link href={ppiResponse[0]?.pdfReport} target="_blank">
                    View
                  </Link>
                )}

                {getItemState(
                  <Download
                    onClick={() =>
                      download(
                        ppiResponse[0]?.pdfReport,
                        "ppi_inspection_result"
                      )
                    }
                  >
                    Download
                  </Download>
                )}
              </Value>
            </Item>
          </ItemGroup>
        )}

        {requestedDocuments?.length > 0 && (
          <ItemGroup>
            <Label>Requested Document</Label>
            {requestedDocuments?.map((document: Documents, index: string) => (
              <Item key={index}>
                <Title>{camelToWords(document.name)}:</Title>
                <Value>
                  <Status>{document.status}</Status>
                </Value>
              </Item>
            ))}
          </ItemGroup>
        )}

        {dlResponse?.loanDocuments?.length > 0 && (
          <ItemGroup>
            <Label>Loan Document</Label>
            {dlResponse?.loanDocuments?.map(
              (document: DocumentsByLoans, index: string) => (
                <Item key={index}>
                  <Title>{camelToWords(document.name)}:</Title>
                  <Value>
                    {getItemState(
                      <Link href={document.fileUrl} target="_blank">
                        View
                      </Link>
                    )}
                    {getItemState(
                      <Download
                        onClick={() =>
                          download(document.fileUrl, document.name)
                        }
                      >
                        Download
                      </Download>
                    )}
                  </Value>
                </Item>
              )
            )}
          </ItemGroup>
        )}
      </Content>
    </Container>
  );
};

export default Documents;
